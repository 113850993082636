angular.module('fingerink')
    .factory('orderService', ['$http', function ($http) {
        var sdo = {

            getOrders: function () {
                return $http({
                    method: 'GET',
                    url: 'orders/',
                    crossDomain: true
                });
            },

            getOrder: function (id) {
                return $http({
                    method: 'GET',
                    url: 'orders/' + id,
                    crossDomain: true
                });
            },
            getActualOrder: function () {
                return $http({
                    method: 'GET',
                    url: 'orders/actual',
                    crossDomain: true
                });
            },

            getInvoices: function () {
                return $http({
                    method: 'GET',
                    url: 'orders/invoices',
                    crossDomain: true
                });
            },

            getUrlFactura:function (invoiceId) {
                return $http({
                    method: 'GET',
                    url: 'orders/URLFactura/'+invoiceId,
                    crossDomain: true
                });
            },

            getNext: function () {
                return $http({
                    method: 'GET',
                    url: 'orders/next',
                    crossDomain: true
                });
            },
            
            deleteNext: function () {
                return $http({
                    method: 'DELETE',
                    url: 'orders/next',
                    crossDomain: true
                });
            },



            addLicensePresupuesto: function () {
                return $http({
                    method: 'GET',
                    url: 'orders/addLicense',
                    crossDomain: true
                });
            },
            addLicense: function (user) {
                return $http({
                    method: 'POST',
                    url: 'orders/addLicense',
                    crossDomain: true,
                    data: user
                });
            },
            buyProductPresupuesto: function (productId) {
                return $http({
                    method: 'GET',
                    url: 'orders/buyOrder/' + productId,
                    crossDomain: true
                });
            },
            buyProduct: function (productId) {
                return $http({
                    method: 'POST',
                    url: 'orders/buyOrder/' + productId,
                    crossDomain: true
                });
            },
            changeNextMonthOrder: function (productId) {
                return $http({
                    method: 'POST',
                    url: 'orders/changeNextMonthOrder/' + productId,
                    crossDomain: true
                });
            }


        };
        return sdo;
    }]);
